// src/components/Tabs.tsx
import React, { useEffect, useRef } from "react"

// Define the props for a single tab
interface TabProps {
    id: string
    label: string
    isActive: boolean
    onClick: (id: string) => void
    onKeyDown: (
        event: React.KeyboardEvent<HTMLButtonElement>,
        index: number
    ) => void
    index: number
}

const Tabs: React.FC<{
    tabs: { id: string; label: string }[]
    activeTab: string
    onTabSelect: (id: string) => void
    children: Iterable<React.ReactNode>
}> = ({ tabs, activeTab, onTabSelect, children }) => {
    const tabsRef = useRef<Array<HTMLButtonElement | null>>([])

    // Focus the active tab when activeTab changes
    useEffect(() => {
        const activeIndex = tabs.findIndex((tab) => tab.id === activeTab)
        if (activeIndex !== -1 && tabsRef.current[activeIndex]) {
            tabsRef.current[activeIndex]?.focus()
        }
    }, [activeTab, tabs])

    return (
        <div>
            <div
                role="tablist"
                aria-label="Sample Tabs"
                style={{ display: "flex", borderBottom: "1px solid #ccc" }}
            >
                {tabs.map((tab, index) => (
                    <button
                        key={tab.id}
                        role="tab"
                        aria-selected={activeTab === tab.id}
                        aria-controls={`panel-${tab.id}`}
                        id={`tab-${tab.id}`}
                        tabIndex={activeTab === tab.id ? 0 : -1}
                        onClick={() => onTabSelect(tab.id)}
                        ref={(el) => (tabsRef.current[index] = el)}
                        style={{
                            padding: "10px 20px",
                            cursor: "pointer",
                            border: "none",
                            borderBottom:
                                activeTab === tab.id
                                    ? "2px solid blue"
                                    : "2px solid transparent",
                            backgroundColor: "transparent",
                            outline: "none",
                            fontWeight:
                                activeTab === tab.id ? "bold" : "normal",
                        }}
                    >
                        {tab.label}
                    </button>
                ))}
            </div>
            {tabs.map((tab, index) => (
                <div
                    key={tab.id}
                    role="tabpanel"
                    id={`panel-${tab.id}`}
                    aria-labelledby={`tab-${tab.id}`}
                    hidden={activeTab !== tab.id}
                    style={{ padding: "20px" }}
                >
                    {activeTab === tab.id && children[index]}
                </div>
            ))}
        </div>
    )
}

export default Tabs
